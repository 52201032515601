import React from 'react';

const ProjectsPreview: React.FC = () => {
  return (
    <div>
      <h2>Projects</h2>
      <ul>
        <li>
            <a href="https://github.com/UfukAkyuzYildirim/SmartHomeHub" target="_blank" rel="noopener noreferrer">
                Smart Home Hub project
            </a>
        </li>
        <li>
            <a href="https://github.com/UfukAkyuzYildirim/ESP32-Projects/tree/main/MotorControl" target="_blank" rel="noopener noreferrer">
                ESP32 8266 Motor Cotnrol
            </a>
        </li>
        <li>
            <a href="https://github.com/UfukAkyuzYildirim/ESP32-Projects/tree/main/HttpDevelopment" target="_blank" rel="noopener noreferrer">
                ESP32 8266 Motor Cotnrol With Web Server
            </a>
        </li>
        <li>
            <a href="https://github.com/mustafakoykolu/NutritionSolution" target="_blank" rel="noopener noreferrer">
                Fitlezzet.com
            </a>
        </li>
      </ul>
    </div>
  );
};

export default ProjectsPreview;
